import React from "react";
import { Link } from "gatsby";
import {
  IoIosTime,
  IoIosPricetags,
  IoIosContacts,
  IoIosPaperPlane,
  IoIosSunny,
  IoIosDisc,
  IoIosPause,
  IoIosContact,
  IoIosThumbsUp,
  IoIosArrowForward,
} from "react-icons/io";

import Image from "../components/image";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PageHeader, Feature, CallToAction } from "../components/site/";

const Mobile = (props) => (
  <Layout>
    <Seo
      title="Mobile Timesheets for Employees | PayHero"
      description="Let your employees record hours, request leave, manage expenses and view payslips from wherever they are."
      pathname={props.location.pathname}
    />
    <PageHeader
      feature="Mobile App"
      title={[
        <span key={0} className="-fontPrimary">
          Run your business
          <br />
        </span>,
        " on the go",
      ]}
      description="Great mobile apps and cloud-based payroll software make it easy to manage timesheets, leave, expenses and payroll – wherever you and your team are."
      imagePath="PayHero_EmpMobile.png"
    />
    <Container>
      <Wrapper>
        <Row stackGap={40} className="-textCenter">
          <Box size={33}>
            <h3>
              <IoIosContacts />
              <br />
              Empower your team
            </h3>
            <p>
              Our beautiful employee mobile app lets your team manage key tasks
              as they’re out and about.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosThumbsUp />
              <br />
              Make payroll a breeze
            </h3>
            <p>
              Timesheets, leave requests and expenses submitted online go
              straight into <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll</Link>. No data entry.
              No hassle.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoIosSunny />
              <br />
              Pay wages from anywhere
            </h3>
            <p>
              Going on holiday? No problem. With Payroll AutoPilot® you can pay
              your staff from the beach or snow.
            </p>
          </Box>
        </Row>
        <hr />
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Mobile Time Tracking | PayHero Mobile"
              filename="PayHero_EmpMobile.png"
              maxWidth={500}
              centerImage
              addShadow
            />
          </Box>
          <Box size={50} stackGap={40}>
            <h2>
              Employee mobile app
              <br />
              <span className="-fontLight">
                Give your staff and managers the tools they need to complete key
                tasks from the palm of their hand.
              </span>
            </h2>
            <Feature
              icon={<IoIosTime key={0} />}
              title="Timesheets in their pocket"
              description="Employees that are out and about on the job can submit timesheets against different work items or cost centres by selecting start, end and break times or using the GPS start/stop timer."
            />
            <Feature
              icon={<IoIosPricetags key={0} />}
              title="Capture expenses with ease"
              description="Expense claims couldn’t be easier. Your employees can take a photo
              of a receipt, categorise it and submit the expense for approval
              from their phone while they’re still at the till."
            />
            <Feature
              icon={<IoIosPaperPlane key={0} />}
              title="Easy leave requests"
              description="Your staff can submit leave requests, check the approval status and see a record of leave already taken and scheduled with a simple calendar view."
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter>
          <Box size={50} stackGap={40}>
            <h2>
              Photo time clock app
              <br />
              <span className="-fontLight">
                No more timesheet stress. Record exact clock in and clock out
                times for fast, accurate payroll.
              </span>
            </h2>
            <Feature
              icon={<IoIosContact key={0} />}
              title="Start shifts with a selfie"
              description={[
                "Ditch the paper timesheets and put your feet up. Launch our ",
                <Link to={process.env.GATSBY_SITE_URL + '/time'} key={0}>
                  time clock
                </Link>,
                " app on a wall-mounted tablet and get your staff to clock in and out of shifts with a selfie photo. ",
              ]}
            />
            <Feature
              icon={<IoIosPause key={0} />}
              title="Manage paid and unpaid breaks"
              description="Automatically take care of your rest and meal break compliance. When staff record break times on the photo time clock app PayHero figures out if they should be paid or unpaid, based on the latest rules."
            />
            <Feature
              icon={<IoIosDisc key={0} />}
              title="Accurate payroll"
              description="You’ll get the most accurate timesheets possible sent straight to payroll. No more timesheet stress."
            />
          </Box>
          <Box size={50}>
            <Image
              alt="Clock In & Out | Photo Time Clock | PayHero"
              filename="PayHeroShift_Home.png"
              maxWidth={550}
              centerImage
              addShadow
              rounded
            />
          </Box>
        </Row>
        <Row stackGap={80} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              alt="Run Payroll on Mobile | PayHero Mobile"
              filename="PayrollOnAnyDevice_PayHero.png"
              addShadow
            />
          </Box>
          <Box size={50} stackGap={40}>
            <div>
              <h2>Pay your staff from anywhere</h2>
              <h4>
                With PayHero Payroll AutoPilot® we’ll automatically run your
                payroll each pay day so you can ensure wages are paid without
                logging in. If you do need to make changes, PayHero works just
                as well on a tablet as a desktop so you can manage payroll from
                anywhere. Payday is easy, even when you’re out of the office.
              </h4>
            </div>
            <Link
              className="link-arrow link-floating"
              to="/blog/payroll-autopilot"
            >
              Discover Payroll AutoPilot® <IoIosArrowForward />
            </Link>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default Mobile;
